<script setup lang="ts">
import { validateNotEmpty, validateEmail } from '@mop/shared/utils/validationRules';
import { scrollToPosition } from '@mop/shared/utils/util';
import type { UiNotificationStatuses } from '@mop/ui2';
import type { Alternate, SeoBreadcrumb } from '@/types/cms';
import { isNumber } from '@/utils/util';

defineOptions({
  name: 'JobApply',
});

const { $gtm, $mopI18n, $urls, $storyblokLivePreview } = useNuxtApp();
const classNameRef = ref(['']);
const loadingRef = ref(false);
const route = useRoute();
const router = useRouter();
const { getCmsStory, trackPageView, cmsStoryModelRef, loadingRef: loadingCms } = useMopCms();
const { initTransition } = useMopPageTransitionClient();
const { fetchJob, jobItemRef, sendApplication, getJobIdFromPath, statusModelRef } = useMopJob();
const { getHeadObject, getAlternatesFromStory, setAlternates, getBreadcrumbSchema } = useMopSeo();
const jobId = getJobIdFromPath(route.params.jobId as string);
initTransition(classNameRef, loadingCms);

const notificationMessageRef = ref('');
const notificationTypeRef = ref<UiNotificationStatuses>('success');
const formEl = ref();
const formStateRef = ref({
  formData: {
    firstName: '',
    lastName: '',
    preferredName: '',
    email: '',
    phone: '',
    files: undefined,
    additionalFiles: undefined,
  },
});
const maxSizeMB = 5;
const maxSize = maxSizeMB * 1024 * 1024;
const rules = {
  validatePhone: (value: any) =>
    /[<>]/g.test(value) === false ||
    cmsStoryModelRef.value.getAttribute('errorPhone') ||
    $mopI18n.t('locale.validate.phone'),
  validateAlphabets: (value: any) =>
    /^[\p{L} \-']+$/giu.test(value.trim()) ||
    cmsStoryModelRef.value.getAttribute('errorNotValid') ||
    $mopI18n.t('locale.validate.not_valid'),
  validateNotEmpty: (value: any) =>
    validateNotEmpty(value) ||
    cmsStoryModelRef.value.getAttribute('errorRequired') ||
    $mopI18n.t('locale.validate.required'),
  validateEmail: (value: string) =>
    validateEmail(value) || cmsStoryModelRef.value.getAttribute('errorEmail') || $mopI18n.t('locale.validate.email'),
  validateFileCount: (value: FileList) => {
    if (!value?.length) {
      return cmsStoryModelRef.value.getAttribute('errorRequired') || $mopI18n.t('locale.validate.required');
    }
    return (
      value.length === 1 ||
      cmsStoryModelRef.value.getAttribute('errorMaxFileCount') ||
      $mopI18n.t('locale.validate.max_file_count')
    );
  },
  validateAdditionalFileCount: (value: FileList) => {
    return (
      !value ||
      value.length <= 10 ||
      cmsStoryModelRef.value.getAttribute('errorMaxFileCount') ||
      $mopI18n.t('locale.validate.max_file_count')
    );
  },
  validateCVFileSize: (value: FileList) => {
    if (!value) {
      return true;
    }
    const remainingSize = Array.from<File>(formStateRef.value.formData.files ?? []).reduce((remainingKB, file) => {
      remainingKB -= file.size;

      return remainingKB;
    }, maxSize);

    if (remainingSize <= 0) {
      return (
        cmsStoryModelRef.value.getAttribute('errorMaxFileSize').replace('{0}', `${maxSizeMB}MB`) ||
        $mopI18n.t('locale.validate.max_file_size', [`${maxSizeMB}MB`])
      );
    }
    return true;
  },
  validateOtherFileSize: (value: FileList) => {
    if (!value) {
      return true;
    }
    const remainingSizeFromCV = Array.from<File>(formStateRef.value.formData.files ?? []).reduce(
      (remainingKB, file) => {
        remainingKB -= file.size;

        return remainingKB;
      },
      maxSize,
    );

    const remainingSize = Array.from<File>(formStateRef.value.formData.additionalFiles ?? []).reduce(
      (remainingKB, file) => {
        remainingKB -= file.size;

        return remainingKB;
      },
      remainingSizeFromCV,
    );

    if (remainingSize <= 0) {
      if (remainingSizeFromCV < 0) {
        return (
          cmsStoryModelRef.value.getAttribute('errorMaxFileSize').replace('{0}', '') ||
          $mopI18n.t('locale.validate.max_file_size', [''])
        );
      }
      const remainingSizeFormatted =
        remainingSizeFromCV < 1024 * 1024
          ? (remainingSizeFromCV / 1024).toFixed(0) + 'KB'
          : (remainingSizeFromCV / 1024 / 1024).toFixed(1) + 'MB';
      return (
        cmsStoryModelRef.value.getAttribute('errorMaxFileSize').replace('{0}', remainingSizeFormatted) ||
        $mopI18n.t('locale.validate.max_file_size', [remainingSizeFormatted])
      );
    }
    return true;
  },
  validateFileType: (value: FileList) => {
    if (!value) {
      return true;
    }
    if (Array.from(value).some((file: File) => file.name.endsWith('pdf') === false)) {
      return cmsStoryModelRef.value.getAttribute('errorFileType') || $mopI18n.t('locale.validate.file_type');
    }
    return true;
  },
};

async function handleSubmit() {
  loadingRef.value = true;
  formStateRef.value.formData.email = formStateRef.value.formData.email.trim();

  const payload = new FormData(formEl.value.$el);
  payload.append('vacancyId', jobId);
  payload.delete('files-temp[]');
  for (const file of formStateRef.value.formData.files! as FileList) {
    payload.append('files[]', file);
  }
  payload.delete('additional-files-temp[]');
  if (formStateRef.value.formData?.additionalFiles) {
    for (const file of formStateRef.value.formData.additionalFiles as FileList) {
      payload.append('additionalFiles[]', file);
    }
  }

  await sendApplication(payload);
  const result: any = statusModelRef.value.getResult();
  if (!isNumber(result) || statusModelRef.value.hasError()) {
    const tranlationKey = `locale.externalJobErrors.${result}`;
    notificationMessageRef.value =
      result && $mopI18n.te(tranlationKey)
        ? $mopI18n.t(tranlationKey)
        : result || cmsStoryModelRef.value.getAttribute('errorSubmit') || $mopI18n.t('locale.jobs.post_error');
    notificationTypeRef.value = 'error';
    scrollToPosition(0);
  } else {
    $gtm.trackEvent({
      eventName: 'purchase',
      customValue: {
        affiliation: 'manual',
        transaction_id: String(result),
        items: [jobItemRef.value.getGtmTrackData()],
      },
    });
    await router.push($mopI18n.localePath(`${$urls.CAREER_THANK_YOU_PAGE}?jobId=${jobId}`));
  }
  loadingRef.value = false;
}

const metaData = computed(() => {
  const careerLink: string = $mopI18n.localePath($urls.CAREER);
  const startCreatingLink: string = $mopI18n.localePath($urls.CAREER_START_CREATING);
  const ourJobsLink: string = $mopI18n.localePath($urls.CAREER_PAGE);
  const title: string = jobItemRef.value.getTitle();
  const description: string = cmsStoryModelRef?.value.getSeo()?.description?.replace('{job-title}', title) || title;
  const isIndexable = true;
  const canonical = useRoute().path;
  let alternates: Alternate[] = getAlternatesFromStory(cmsStoryModelRef.value);
  alternates = alternates.map((alternate: Alternate) => {
    const hrefSplit: string[] = alternate.href.split('/');
    const href = `${hrefSplit.join('/')}/${jobItemRef.value.getUrl()}`;
    return {
      href,
      lang: alternate.lang,
    };
  });
  setAlternates(alternates);

  const breadcrumbs: SeoBreadcrumb[] = [
    {
      name: $mopI18n.t('locale.blog.name_career'),
      url: careerLink,
    },
    {
      name: $mopI18n.t('locale.jobs.start_creating_with_us'),
      url: startCreatingLink,
    },
    {
      name: $mopI18n.t('locale.jobs.search_button'),
      url: ourJobsLink,
    },
    {
      name: title,
      url: route.path,
    },
  ];
  const ogDescription: string = jobItemRef.value.getDescription1();
  const ogImage: string = cmsStoryModelRef?.value.getAttribute('ogImage');
  const headObject = getHeadObject({
    title,
    description,
    isIndexable,
    canonical,
    alternates,
    ogDescription,
    ogImage,
  });

  return { ...headObject, ...getBreadcrumbSchema(breadcrumbs) };
});

onMounted(async () => {
  const language = await fetchJob(jobId);
  await getCmsStory('/career/start-creating-with-us/our-jobs/apply', {
    language: language || 'default',
  });
  if (!jobItemRef.value.getId() && !$storyblokLivePreview.isEnabledInIframe) {
    throw createError({
      statusCode: 404,
      message: 'NOT_FOUND',
    });
  } else {
    trackPageView('Career');
    $gtm.trackEvent({
      eventName: 'begin_checkout',
      customValue: {
        items: [jobItemRef.value.getGtmTrackData()],
      },
    });
  }
});

$storyblokLivePreview.initStoryListener('Storyblok', cmsStoryModelRef);

useHead(metaData);
</script>

<template>
  <div :class="['job-apply', 'transition', ...classNameRef]">
    <MopPageLoad :class="classNameRef" />

    <div class="transition-content">
      <Ui2Notification v-if="notificationMessageRef" :status="notificationTypeRef" type="primary" show-icon>{{
        notificationMessageRef
      }}</Ui2Notification>

      <MopCmsContentElementsSlot
        id="job-apply__content-top"
        class="job-apply__content-top"
        no-padding
        :elements="cmsStoryModelRef.getContentElements('bodyTop')"
      />

      <h2 class="job-apply__subline">
        <NuxtLink
          class="job-apply__link-to-job"
          :to="$mopI18n.localePath(`${$urls.CAREER_PAGE}/${jobItemRef.getUrl()}`)"
          no-prefetch
        >
          {{ jobItemRef.getTitle() }}
        </NuxtLink>
      </h2>

      <Ui2Form ref="formEl" class="job-apply__form" long @submit="handleSubmit">
        <div class="job-apply__subline">
          {{ cmsStoryModelRef.getAttribute('titlePersonalData') || $mopI18n.t('locale.jobs.personal_details') }}
        </div>

        <Ui2TextFieldInput
          id="first-name"
          v-model="formStateRef.formData.firstName"
          name="first-name"
          :label="cmsStoryModelRef.getAttribute('firstName') || $mopI18n.t('locale.contact.label.first_name')"
          floating
          :rules="[rules.validateNotEmpty, rules.validateAlphabets]"
          required
          autocomplete="given-name"
        />

        <Ui2TextFieldInput
          id="last-name"
          v-model="formStateRef.formData.lastName"
          name="last-name"
          :label="cmsStoryModelRef.getAttribute('lastName') || $mopI18n.t('locale.contact.label.last_name')"
          floating
          :rules="[rules.validateNotEmpty, rules.validateAlphabets]"
          required
          autocomplete="family-name"
        />

        <Ui2TextFieldInput
          id="preferred-name"
          v-model="formStateRef.formData.preferredName"
          name="preferred-name"
          :label="cmsStoryModelRef.getAttribute('preferredName') || $mopI18n.t('locale.contact.label.preferred_name')"
          floating
          :rules="[rules.validateNotEmpty, rules.validateAlphabets]"
          required
          autocomplete="preferred-name"
        />

        <Ui2TextFieldInput
          id="email"
          v-model="formStateRef.formData.email"
          name="email"
          type="email"
          :label="cmsStoryModelRef.getAttribute('email') || $mopI18n.t('locale.contact.label.email')"
          floating
          :rules="[rules.validateNotEmpty, rules.validateEmail]"
          required
          autocomplete="email"
        />

        <Ui2TextFieldInput
          id="phone"
          v-model="formStateRef.formData.phone"
          name="phone"
          type="phone"
          :label="cmsStoryModelRef.getAttribute('phone') || $mopI18n.t('locale.contact.label.phone')"
          floating
          :rules="[rules.validateNotEmpty, rules.validatePhone]"
          required
          autocomplete="tel"
        />
        <div class="job-apply__form-group">
          <div class="job-apply__form-label">
            {{ cmsStoryModelRef.getAttribute('titleUploadFile') || $mopI18n.t('locale.jobs.upload_file') }}
          </div>
          <Ui2FileUpload
            id="files"
            v-model="formStateRef.formData.files"
            name="files-temp[]"
            :rules="[rules.validateFileCount, rules.validateFileType, rules.validateCVFileSize]"
            accept=".pdf"
            :localisation="{
              click_to_upload: cmsStoryModelRef.getAttribute('uploadFileCta'),
              drag_content: cmsStoryModelRef.getAttribute('uploadFileCtaAdditional'),
              drag_document_here: cmsStoryModelRef.getAttribute('uploadFileCtaDrag'),
              unsupported_file: cmsStoryModelRef.getAttribute('uploadFileUnsupportedFile'),
            }"
          />
        </div>
        <div class="job-apply__form-group">
          <div class="job-apply__form-label">
            {{
              cmsStoryModelRef.getAttribute('titleUploadAdditionalFiles') ||
              $mopI18n.t('locale.jobs.upload_file_additional')
            }}
          </div>
          <Ui2FileUpload
            id="filesOther"
            v-model="formStateRef.formData.additionalFiles"
            name="additional-files-temp[]"
            :rules="[rules.validateAdditionalFileCount, rules.validateFileType, rules.validateOtherFileSize]"
            accept=".pdf"
            multiple
            :localisation="{
              click_to_upload: cmsStoryModelRef.getAttribute('uploadFileCta'),
              drag_content: cmsStoryModelRef.getAttribute('uploadFileCtaAdditional'),
              drag_document_here: cmsStoryModelRef.getAttribute('uploadFileCtaDrag'),
              unsupported_file: cmsStoryModelRef.getAttribute('uploadFileUnsupportedFile'),
            }"
          />
        </div>
        <div class="job-apply__form-group">
          <Ui2Button
            :label="cmsStoryModelRef.getAttribute('cta') || $mopI18n.t('locale.jobs.submit')"
            :disabled="loadingRef"
            :loading="loadingRef"
            html-type="submit"
            size="lg"
          />
        </div>
        <div class="job-apply__form-group">
          <Ui2Button
            :label="cmsStoryModelRef.getAttribute('goBack') || $mopI18n.t('locale.jobs.go_back')"
            type="ghost"
            icon-start="arrow-left"
            html-type="link"
            :to="$mopI18n.localePath(`${$urls.CAREER_PAGE}/${jobItemRef.getUrl()}`)"
            size="lg"
            full-width
          />
        </div>
      </Ui2Form>

      <MopCmsContentElementsSlot
        id="job-apply__content-bottom"
        class="job-apply__content-bottom"
        no-padding
        :elements="cmsStoryModelRef.getContentElements('bodyBottom')"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.job-apply {
  padding: 0 $global-padding;
  max-width: 470px;
  margin: 0 auto;
}
.job-apply__content-top {
  text-wrap: nowrap;

  @include v2-apply-upto('mobile') {
    text-wrap: wrap;
  }
}

.job-apply__form {
  display: flex;
  flex-direction: column;
  gap: $space-12;
  margin-top: $space-24;
}

.job-apply__subline {
  @include v2-text-style(sm, highlight);
  padding: 0;
  margin: 0;
}

.job-apply__form-group {
  display: flex;
  flex-direction: column;
  gap: $space-12;
  margin-top: $space-12;
}

.job-apply__form-label {
  @include v2-text-style(sm, highlight);
}

.job-apply__link-to-job {
  @include v2-text-style(sm, highlight);
}

.job-apply__notification {
  margin: 0 $global-padding;
}

.job-apply__content-bottom {
  margin: $space30 0;
}
</style>
